export const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    boxShadow: "1px 2px 3px rgba(0, 0, 0, 0.0965523)",
    border: "none",
    borderRadius: "8px",
    padding: "0",
    overflow: "visible",
  },
  overlay: {
    background: "rgba(47, 47, 47, 0.15)",
  },
}
export const SignUpModalStyles = {
  content: {
    ...customStyles.content,
    width: "360px",
  },
  overlay: {
    ...customStyles.overlay,
  },
}
